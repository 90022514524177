<template>
	<main>
		<div id="top" nitrohosting-id="S1Yc4ktoGy7lRV8o" class="nitrohosting-row nitrohosting-row-stretch-full nitrohosting-height-fit">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-full">
				<div nitrohosting-id="J1y7IXxgvisoHaIG" class="nitrohosting-col nitrohosting-col-9">
					<div class="nitrohosting-col-holder">
						<div nitrohosting-id="39znwjlF0Th1Gcxc" class="nitrohosting-heading" style="text-align: left;">
							<div class="nitrohosting-heading-holder">Powerful Server For Gaming</div>
						</div>
						<div nitrohosting-id="nBaUhKmR7rkjxGZO" class="nitrohosting-heading" style="text-align: left;">
							<div class="nitrohosting-heading-holder">Game Hosting</div>
						</div>
					</div>
				</div>
				<div nitrohosting-id="cO83NDDMWrjmrxk8" class="nitrohosting-col nitrohosting-col-3 nitrohosting-hide-mobile">
					<div class="nitrohosting-col-holder">
						<div nitrohosting-id="gDmxrsBHrCCRx4Bl" class="nitrohosting-btn nitrohosting-hide-mobile">
							<a v-scroll-to="'#games'" class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-right"><span class="nitrohosting-btn-text">Choose Game</span></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="feature" nitrohosting-id="FCzMntp9Sb9AdEI6" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="BpmDLtmttIDMUFlV" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="HgcuORXpZge3eiiW" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder">Features</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="NIUdxwsmHskryQDK" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div nitrohosting-id="YbVzofdzbqYgakaO" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="lkaFAIDyrgts8X5d" class="nitrohosting-col">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="s45X6NXnX6k4tRdt" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-microchip" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">High Clock Speed CPU</div>
													<div class="nitrohosting-service-text">
														<p>Our servers are equipped with high speed CPU on every plans.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="XkkHORlnLsmUuP3m" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-hdd" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">RAID M.2 NVMe Storage</div>
													<div class="nitrohosting-service-text">
														<p>Delivering extreme performance and protecting your data.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="itHzRejoxaTkDfTS" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-network-wired" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Balanced Server Loads</div>
													<div class="nitrohosting-service-text">
														<p>We have balanced system load, avoiding unexpected downtime.<br />
														</p></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="vqHBpIRe7YkL1NQ6" class="nitrohosting-col">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="4DtpiW0FoLuYP1ZO" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-memory" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">DDR4 ECC RAM</div>
													<div class="nitrohosting-service-text">
														<p>We use modern DDR4 ECC RAM preventing data corruption.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="gSDcnL8ubH7Pw9KV" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-shield-alt" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Advanced DDoS Protection</div>
													<div class="nitrohosting-service-text">
														<p>Our DDoS mitigation technology scan and block malicious traffic.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="t5uKyofHCf10H2lQ" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-user-friends" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Quality Support</div>
													<div class="nitrohosting-service-text">
														<p>Our staffs are well trained and knowledgeable, all ready for you.<br />
														</p></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="games" nitrohosting-id="D2mYtgaUjuiVDN29" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="xaQFAp8pwnYbkBaO" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="iFSQf0INUqisiGUS" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder">Select Your Choice of Game</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="O11QsWW3KoviodcE" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="3bzc2Ab1fOvp0Zmi" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="wC85OmhRVhftA7UX" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="o3i2GCTu1ObTot2v" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">Focus on your creative, not the lags</div>
										</div>
										<div nitrohosting-id="12PNqWY8mqAGaMtB" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">Minecraft</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="zjugV4PwoHJE2rlo" class="nitrohosting-col nitrohosting-col-1">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="6OfOPCobG0FSyTkX" class="nitrohosting-btn nitrohosting-animation-grow">
											<router-link class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-right" :to="{name: 'Minecraft'}">
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i>
												<span class="nitrohosting-btn-text">Start Building</span>
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="X1Hu1bUbfR7VQquj" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="OVpWPMD4i4e2yQYL" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="3bC5xW9xaa7uXQ4D" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">Powerful hardware for all modders</div>
										</div>
										<div nitrohosting-id="z6vMxh65qly1tzpp" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">Garry's Mod</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="G2kCwwQPP7qx3UFw" class="nitrohosting-col nitrohosting-col-1">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="IPzH8JGxiGjVOipf" class="nitrohosting-btn nitrohosting-animation-grow">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-right">
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i>
												<span class="nitrohosting-btn-text">Coming Soon</span>
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i> </a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="uF0CaQtCQoicRZUF" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="ww9BqtLEOPmHAd5V" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="W9rQcPQAwFE2VtO7" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">Better performance with better excitement</div>
										</div>
										<div nitrohosting-id="DSxLxuL0OSr7Vq8i" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">CS:GO</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="6y3PfM8H7xhxi8FJ" class="nitrohosting-col nitrohosting-col-1">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="dQpFn3xCKbN1FLeR" class="nitrohosting-btn nitrohosting-animation-grow">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-right">
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i>
												<span class="nitrohosting-btn-text">Coming Soon</span>
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i> </a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="BC4bfVAam79o8woS" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="kEznNo2tA2Ms1Zx9" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="l2ruwgsw8b3UhPXS" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">Do not let the lag stop your adventure</div>
										</div>
										<div nitrohosting-id="vTpdAdLetHQYlLPw" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">Ark: Survival Evolved</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="2attCp6sX8jZ1RxU" class="nitrohosting-col nitrohosting-col-1">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="NOIkLkW5AtCHcpjX" class="nitrohosting-btn nitrohosting-animation-grow">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-right">
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i>
												<span class="nitrohosting-btn-text">Coming Soon</span>
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i> </a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="soxQbs5xCDG3hJWE" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="GtQNQrr4Ry587z77" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="X9WKSiD9g4X14hcl" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">It is more fun with a good server</div>
										</div>
										<div nitrohosting-id="wsBmCTWXq1O1577z" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">Team Fortress 2</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="rtsuDfsFI6rEmRbR" class="nitrohosting-col nitrohosting-col-1">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="rbC50bo8Hcm7fUVH" class="nitrohosting-btn nitrohosting-animation-grow">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-right">
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i>
												<span class="nitrohosting-btn-text">Coming Soon</span>
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i> </a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="KjoL9un33ABtr7MS" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="c6pQzJh3BuzRI1oG" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="k3GH7OrYN8YMnLlZ" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">Now you can play heavy mods with friends</div>
										</div>
										<div nitrohosting-id="KSc1hNwUGWVxclw0" class="nitrohosting-heading" style="text-align: left;">
											<div class="nitrohosting-heading-holder">Unturned</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="69yAQUjSQsnYbGAD" class="nitrohosting-col nitrohosting-col-1">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="ILx6NoL4tP6r7ZAe" class="nitrohosting-btn nitrohosting-animation-grow">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-right">
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i>
												<span class="nitrohosting-btn-text">Coming Soon</span>
												<i class="fas fa-caret-right nitrohosting-btn-icon"></i> </a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="UtoUFONW1FS1e4zp" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="THnsFQUXnkMtOZTL" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="TOWYBphsnwx67Kdi" class="nitrohosting-heading" style="text-align: center;">
											<a href="https://billing.nitrohosting.pro/submitticket.php?step=2&amp;deptid=3&amp;subject=Suggesting+New+Service&amp;message=I+would+like+to+suggest+adding+the+following+service:">
												<div class="nitrohosting-heading-holder">Feel like we are missing what you need? Click here and let us know!</div>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
	export default {
		name: "Game",
		metaInfo: {
			title: "Game Hosting",
			titleTemplate: "%s - NitroHosting"
		}
	};
</script>
<style scoped>
	@import "../assets/css/pages/Game.css";
</style>